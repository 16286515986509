import { fetchJson } from "./FetchJsonProvider";
import { stringify } from "query-string";
import config from "../config";
import moment from "moment";

// eslint-disable-next-line
Date.prototype.toJSON = function () {
    return moment(this).format();
};

const apiUrl = config.apiGateway.URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const token = localStorage.getItem("accesstoken");
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchJson(url, options);
};

const DataProvider = {
    getList: (resource, params) => {

        if (resource === 'about' || resource === 'technology' || resource === 'contact') {
            return DataProvider.getSingle(resource)
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sortField: field,
            sortDirection: order,
            pageIndex: JSON.stringify(page - 1),
            itemsPerPage: JSON.stringify(perPage),
            filter: JSON.stringify(params.filter)
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            return {
                data: json,
                total: parseInt(
                    headers
                        .get("content-range")
                        .split("/")
                        .pop(),
                    10
                )
            };
        });
    },

    getOne: (resource, params) => {
        if (resource === 'about' || resource === 'technology' || resource === 'contact') {
            return httpClient(`${apiUrl}/${resource}`).then(({ json }) => {
                return ({
                    data: {
                        id: json.id,
                        created: json.crated,
                        modified: json.modified,
                        pl: json.translations.pl,
                        en: json.translations.en,
                        no: json.translations.no,
                    }
                })
            })
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            return ({
                data: {
                    id: json.id,
                    created: json.crated,
                    modified: json.modified,
                    sortOrder: json.sortOrder,
                    layoutType: json.layoutType,
                    pl: json.translations.pl,
                    en: json.translations.en,
                    no: json.translations.no,
                }
            })
        })
    },

    getSingle: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({ json }) => ({ data: [json], total: 1 }))
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sortField: field,
            sortDirection: order,
            pageIndex: JSON.stringify(page - 1),
            itemsPerPage: JSON.stringify(perPage),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(
                headers
                    .get("content-range")
                    .split("/")
                    .pop(),
                10
            )
        }));
    },

    update: (resource, params) => {
        const endParams = {
            id: params.data.id,
            sortOrder: params.data.sortOrder,
            layoutType: params.data.layoutType,
            translations: {
                en: {
                    ...params.data.en,
                    languageId: 1
                },
                pl: {
                    ...params.data.pl,
                    languageId: 2
                }
            }
        }

        if (resource === 'home-page-sections') {
            endParams.translations.no = {
                ...params.data.no,
                languageId: 3
            }
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: "PUT",
            body: JSON.stringify(endParams)
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        const endParams = {
            sortOrder: params.data.sortOrder,
            layoutType: params.data.layoutType,
            translations: {
                en: {
                    ...params.data.en,
                    languageId: 1
                },
                pl: {
                    ...params.data.pl,
                    languageId: 2
                }
            }
        }

        if (resource === 'home-page-sections') {
            endParams.translations.no = {
                ...params.data.no,
                languageId: 3
            }
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(endParams)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id }
        }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "DELETE",
            body: JSON.stringify({ id: params.id })
        }).then(({ json }) => ({ data: json }))
};

export default DataProvider;